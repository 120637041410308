import { createNativeStackNavigator } from "@react-navigation/native-stack";

import MemberType from "common/types/MemberType";

import MyAccountScreen from "../screens/account/MyAccountScreen";
import PersonalInfoScreen from "../screens/account/PersonalInfoScreen";
import EditPersonalInfoScreen from "../screens/account/EditPersonalInfoScreen";
import SettingsScreen from "../screens/account/SettingsScreen";
import HelpScreen from "../screens/account/HelpScreen";
import FamilySharingScreen from "../screens/account/FamilySharingScreen";
import DoctorSharingScreen from "../screens/account/DoctorSharingScreen";
import ConfirmSharingScreen from "../screens/account/ConfirmSharingScreen";
import MemberTypeInner from "common/types/common/MemberTypeInner";
import useScreenType from "../hooks/useScreenType";
import { Header, ScreenOrientation } from "./NavigationUtils";
import LocalizedStrings from "../helpers/LocalizedStrings";
import OffTimeScreen from "../screens/account/OffTimeScreen";
import AddEditOffTimeScreen from "../screens/account/AddEditOffTimeScreen";

export type MyAccountStackParamList = {
  MyAccount: undefined;
  Help: undefined;
  Settings: undefined;
  FamilySharing: undefined;
  ConfirmFamilySharing: { email: string };
  DoctorSharing: undefined;
  ConfirmDoctorSharing: { doctors: MemberType };
  Offtime: undefined;
  AddOffTime: undefined;
  EditOffTime: {
    offtime_id?: string;
  };
  PersonalInfo: undefined;
  EditPersonalInfo: { values?: Partial<MemberTypeInner> };
  Feedback: undefined;
};

const Stack = createNativeStackNavigator<MyAccountStackParamList>();
const MyAccountStackNavigator = () => {
  const { type } = useScreenType();
  return (
    <Stack.Navigator
      screenOptions={{
        header: Header,
        orientation: ScreenOrientation(type)
      }}
    >
      <Stack.Screen
        name="MyAccount"
        component={MyAccountScreen}
        options={{ title: LocalizedStrings.screens.myAccount.title }}
      />
      <Stack.Screen
        name="Help"
        component={HelpScreen}
        options={{ title: LocalizedStrings.screens.help.title }}
      />
      <Stack.Screen
        name="Settings"
        component={SettingsScreen}
        options={{ title: LocalizedStrings.screens.settings.title }}
      />
      <Stack.Screen
        name="PersonalInfo"
        component={PersonalInfoScreen}
        options={{ title: LocalizedStrings.screens.personalInformation.title }}
      />
      <Stack.Screen
        name="Offtime"
        component={OffTimeScreen}
        options={{ title: LocalizedStrings.screens.offTime.title }}
      />
      <Stack.Screen
        name="AddOffTime"
        component={AddEditOffTimeScreen}
        options={{ title: LocalizedStrings.screens.addEditOffTime.titleAdd }}
      />
      <Stack.Screen
        name="EditOffTime"
        component={AddEditOffTimeScreen}
        options={{ title: LocalizedStrings.screens.addEditOffTime.titleEdit }}
      />
      <Stack.Screen
        name="FamilySharing"
        component={FamilySharingScreen}
        options={{ title: LocalizedStrings.screens.familySharing.title }}
      />
      <Stack.Screen
        name="ConfirmFamilySharing"
        component={ConfirmSharingScreen}
        options={{ title: LocalizedStrings.screens.familySharing.title }}
      />
      <Stack.Screen
        name="DoctorSharing"
        component={DoctorSharingScreen}
        options={{ title: LocalizedStrings.screens.doctorSharing.title }}
      />
      <Stack.Screen
        name="ConfirmDoctorSharing"
        component={ConfirmSharingScreen}
        options={{ title: LocalizedStrings.screens.doctorSharing.title }}
      />
      <Stack.Screen
        name="EditPersonalInfo"
        component={EditPersonalInfoScreen}
        options={{ title: LocalizedStrings.screens.editPersonalInfo.title }}
      />
    </Stack.Navigator>
  );
};

export default MyAccountStackNavigator;
