import { Linking } from "react-native";

import {
  LinkingOptions,
  getPathFromState,
  getStateFromPath
} from "@react-navigation/native";
import { useSelector } from "react-redux";

import EnvVars from "common/config/EnvVars";
import { RootState } from "common/redux";

import { TabsParamList } from "../navigation/TabNavigator";
import { DrawerParamList } from "../navigation/drawer/DrawerNavigator";
import { AuthStackParamList } from "../navigation/AuthStackNavigator";

const getWebUrl = () => {
  if (EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV === "prod")
    return ["https://www.mycopilotiq.com", "https://mycopilotiq.com"];
  else
    return [
      `https://${EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV}.mycopilotiq.com`
    ];
};

// For firebase dynamic links check out https://reactnavigation.org/docs/deep-linking/
const useLinkingConfiguration = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  const linkingConfiguration: LinkingOptions<
    TabsParamList | DrawerParamList | AuthStackParamList
  > = {
    getInitialURL: async () => {
      const url = await Linking.getInitialURL();

      return url;
    },
    subscribe(listener) {
      const linkingSubscription = Linking.addEventListener("url", ({ url }) => {
        listener(url);
      });

      return () => {
        linkingSubscription.remove();
      };
    },
    prefixes: ["copilotiq://", ...getWebUrl()],
    getStateFromPath: (path, options) => {
      if (
        !isLoggedIn &&
        path.includes("video/") &&
        !path.includes("login/video/")
      ) {
        const newPath = "/login/" + path;
        return getStateFromPath(newPath?.replace("//", "/"), options);
      }

      if (
        !isLoggedIn &&
        path.includes("tracking/") &&
        !path.includes("login/tracking/")
      ) {
        const newPath = "/login/" + path;
        return getStateFromPath(newPath?.replace("//", "/"), options);
      }

      return getStateFromPath(path, options);
    },
    getPathFromState: (state, options) => {
      const { routes, index } = state;
      const lastElement = routes[index];
      if (!isLoggedIn && lastElement.name === "VideoCall") {
        const replacedPath = lastElement?.path?.replace("/login", "");
        return replacedPath;
      }

      if (!isLoggedIn && lastElement.name === "USPSTracking") {
        const replacedPath = lastElement?.path?.replace("/login", "");
        return replacedPath;
      }

      return getPathFromState(state, options);
    },
    config: {
      // @ts-ignore
      screens: {
        Reauthentication: "reauthentication",
        Landing: "landing",
        PhoneInput: "login/start",
        SMSCode: "login/details",
        MissingLegalForms: "login/legal_forms",
        Biometrics: "login/biometrics",
        TurnOnNotifications: "login/notifications",
        VideoCall: "login/video/:code",
        USPSTracking: "login/tracking/:trackingNumber",

        Home: {
          initialRouteName: "Dashboard",
          screens: {
            Dashboard: "home",
            Notification: "home/notifications",
            MyGoals: "home/my_goals",
            EditGoal: "home/my_goals/edit_goal",
            GoalDetails: "home/my_goals/details",
            AddGoalCategory: "home/my_goals/add_category",
            AddGoal: "home/my_goals/add",
            YourCareTeam: "home/your_care_team",
            BookAppointment: "home/book_appointment/:event_id",
            BookAppointmentConfirmation:
              "home/book_appointment/:event_id/confirm",
            Chat: "home/chat",
            MyAppointments: "home/appointments",
            ConfirmAppointment: "home/appointments/confirm/:appointment_id"
          }
        },
        "Your Care Team": {
          initialRouteName: "YourCareTeam",
          screens: {
            YourCareTeam: "your_care_team"
          }
        },
        Readings: {
          initialRouteName: "MyReadings",
          screens: {
            MyReadings: "my_readings",
            BloodReadingDetails:
              "my_readings/blood_reading_details/:readingType",
            GlucoseReadingDetails:
              "my_readings/glucose_reading_details/:readingType",
            ReadingsHistory: "my_readings/readings_history/:readingType",
            ChartFull: "my_readings/chart/:readingType",
            VideoCall: "video/:code",
            USPSTracking: "tracking/:trackingNumber"
          }
        },
        Account: {
          initialRouteName: "MyAccount",
          screens: {
            MyAccount: "account",
            Help: "account/help",
            Settings: "account/settings",
            PersonalInfo: "account/personal_info",
            FamilySharing: "account/family_sharing",
            ConfirmFamilySharing: "account/family_sharing/confirm",
            DoctorSharing: "account/doctor_sharing",
            ConfirmDoctorSharing: "account/doctor_sharing/confirm",
            EditPersonalInfo: "account/personal_info/edit",
            Offtime: "account/offtime",
            AddOffTime: "account/offtime/add",
            EditOffTime: "account/offtime/edit/:offtime_id"
          }
        }
      }
    }
  };
  return linkingConfiguration;
};

export default useLinkingConfiguration;
